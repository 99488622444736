.Block {
    &--jobs {
        .Tagline, .Alfa, .Description {
            max-width: u(800);
        }

        &.is-related {
            padding: 0;
            margin-bottom: u(100);

            .Bars {
                grid-template-columns: repeat(1, 1fr);
                margin-bottom: u(40);
            }
        }
        
        &.is-overview {
            padding: 0;
        }
    }
}