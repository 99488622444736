/* Social Media */
.Nav--socialMedia {
    // List
    .Nav-list {
        margin-left: u($spacing-unit / -2);
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        @include mq($from: $viewport--md) {
            justify-content: flex-start;
        }
    }

    // Item
    .Nav-item {
        margin-left: u($spacing-unit/2);
    }

    // Link
    .Nav-link {
        &,
        &:active,
        &:focus,
        &:hover {
            color: $cc-grey-medium;
        }
    }

    svg {
        height: 1.6rem;
        width: 1.6rem;
        > * {
            fill: $cc-grey-medium;
        }
    }

    .Header & {
        margin-top: u($spacing-unit);

        @include mq($from: $viewport--md) {
            display: flex;
            margin-top: 0;
            margin-left: 2.25rem;
            border-left: 1px solid $cc-grey-medium;
            padding-left: 1.5rem;
        }

        // Item
        .Nav-item {
            margin-left: u($spacing-unit/2);

            @include mq($until: $viewport--md - 1) {
                .show-nav & {
                    @include transition(#{opacity, transform}, #{300ms, 1s}, #{linear, cubic-bezier(.19,1,.22,1)});

                    @for $i from 1 through 10 {
                        $delay: (0.02s * $i);

                        &:nth-child(#{$i}) {
                            transition-delay: $delay;
                        }
                    }
                }
                html:not(.show-nav) & {
                    opacity: 0;
                    transform: translateY(#{u($spacing-unit)});
                }
            }
        }

        svg {
            @include mq($until: $viewport--md - 1) {
                height: 2.8rem;
                width: 2.8rem;
            }
        }
    }

    .Footer & {
        padding-left: 0;
        border-left: 0;

        // List
        .Nav-list {
            @include mq($until: $viewport--md - 1) {
                justify-content: flex-start;
            }
        }
    }
}
