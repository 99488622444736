.Block {
    &--testimonials {
        padding-top: u(120);
        padding-bottom: u(80);

        &::after { 
            content: "";
            height: 50%;
            width: 100%;
            background-color: $cc-grey-dark;
            position: absolute;
            bottom: 0;
        }

        .Alfa {
            max-width: u(700);
        }

        .Text {
            margin-bottom: u(60);
            max-width: u(460);
        }

        .Container {
            @include mq($until: $viewport--md - 1) {
                padding-right: 0;
            }
        }

        .Slider {
            width: calc(#{$container-width}px + ((100vw - #{$container-width}px) / 2));

            @include mq($until: 1440) {
                width: calc(100vw - 4rem);
            }

            @include mq($until: $viewport--md - 1) {
                width: 100%;
            }

            &-button--next {
                z-index: 3;
                right: u(0);
                left: auto;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
            }

            &-item {
                max-width: u(413);

                @include mq($until: $viewport--md - 1) {
                    max-width: u(280);
                }
            }
        }

        .Testimonial {
            position: relative;
            margin-top: u(20);

            &::after {
                content: "";
                @include position(absolute, 0, 0, 0, 0, 1);
                background: linear-gradient(180deg, rgba(64, 64, 65, 0.25) 46.71%, #404041 100%);
            }

            .Quote {
                position: absolute;
                top: u(-20);
                left: u(20);
                z-index: 2;
            }

            &--inner {
                @include position(absolute, 0, 0, 0, 0, 2);
                padding: u(40, 45);
                color: $white;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                @include mq($until: $viewport--md - 1) {
                    padding: u(40, 20);
                }

                p {
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                .Name {
                    font-weight: 700;
                    margin-top: u(10);
                }
            }
        }
    }
}