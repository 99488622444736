/* Slider: navigation */
.Slider-button {
    @include dimensions(40, 40);
    @include position(absolute, $top: 50%, $z: 10);
    background-color: $primary-color;
    color: $white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .25s ease, opacity .15s ease;
    margin-top: 10rem;
    @include mq($from: $viewport--sm) {
        margin-top: 8rem;
    }

    &.is-disabled {
        cursor: auto;
        opacity: 0;
        pointer-events: none;
    }

    &:after {
        font-family: Slider-icons;
        font-size: 1.5rem;
        text-transform: none !important;
        letter-spacing: 0;
        text-transform: none;
        font-variant: initial;
        line-height: 1;
    }

    &.is-locked {
        display: none;
    }

    &:hover {
        background-color: darken($primary-color, 8%);
    }
}

.Slider-button--prev,
.Slider--rtl .Slider-button--next {
    right: calc(50% + 50vw);
    left: auto;
    margin-right: -4rem;
    &:after {
        content: 'prev';
    }
}

.Slider-button--next,
.Slider--rtl .Slider-button--prev {
    left: calc(50% + 50vw);
    margin-left: -4rem;
    &:after {
        content: 'next';
    }
}
