.Job {
    &-detail {

        .Button {
            &.is-back {
                color: $cc-blue;
                padding-left: 0;

                .Icon {
                    transform: rotate(180deg);
                }
            }
        }

        .Form {
            background-color: $cc-grey-light;
            padding: u(40, 60);
            margin-bottom: u(60);

            &-item--file {
            
                .Form-field--label {
                    background-color: $cc-grey-light;
                }
            } 

            @include mq($until: $viewport--md - 1) {
                padding: u(40, 40);
                width: calc(100% + 40px);
                margin-left: u(-20);
            }

            @include mq($until: $viewport--sm - 1) {
                padding: u(40, 20);
            }

            &-field--file {
                .Form-indicator {
                    &::after {
                        background-color: $cc-grey-medium;
                        background-image: none;
                    }
                }
            } 
        }
    }
        
    &--content {
        margin-bottom: u(120);

        .Container {
            padding-top: 0;
            padding-bottom: 0;
        }

        .Title {
            color: $cc-blue;
            margin-bottom: u(20);
        }

        .Text {
            margin-bottom: u(60);
        }

        .Media {
            margin-bottom: u(60);
        }
    }
}

.Jobs {
    &-filter {
        margin-bottom: u(60);
        
        .Container {
            display: flex;
        }

        .Company {
            width: u(160);
            height: u(60);
            border: 1px solid $cc-blue;
            border-radius: u(10);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: u(10);

            g {
                opacity: 0.75;
                transition: all 0.5s ease;
            }

            path {
                fill: $cc-grey-medium;
                transition: all 0.5s ease;
            }

            &.is-active, 
            &:hover {
                g {
                    opacity: 1;
                    transition: all 0.5s ease;
                }

                path {
                    fill: $cc-blue;
                    transition: all 0.5s ease;
                }
            }

            .Media {
                width: u(160);
                height: u(60);
                position: relative;

                &-object {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: calc(100% - 20px) !important;
                }
            }
        }
    }

    &-overview {
        position: relative;
        margin-bottom: u(120);

        @include mq($until: $viewport--md) {
            margin-bottom: u($spacing-unit * 2);
        }

        .Container {
            background-color: $cc-grey-light;
            padding: u(60);
            max-width: calc(#{$container-width}px + 120px);
        }
    }
}