/* Bar */

.Bars {
    display: grid;
    grid-column-gap: u($spacing-unit*2);
    grid-row-gap: u($spacing-unit);
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    @include mq($from: $viewport--md) {
        grid-template-columns: repeat(2, 1fr);
    }
    > .Bar {

    }
}

.Bar {
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $text-color;

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        height: 1px;
        width: 100%;
        background-color: rgba($cc-grey-dark, .1);
    }

    //padding: u($spacing-unit);
    &-image {
        flex-basis: 8rem;
        background-color: $cc-grey-dark;
        &-inner {
            position: relative;
            padding-bottom: 100%;
            overflow: hidden;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: block;
            object-fit: cover;
            transition: transform .2s ease-out, opacity .35s ease;
        }
        &:before {

        }
    }
    &-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        flex-grow: 1;
        padding: .8rem 6rem .8rem 2.4rem;
        @include mq($from: $viewport--md) {
            padding: .8rem 8rem .8rem 2.4rem
        }
        h4 {
            color: $primary-color;
            margin-bottom: .6rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        p {
            color: $text-color;
        }
        *:last-child {
            margin-bottom: 0;
        }
    }
    &-icon {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform .2s ease-out .05s;
        @include mq($from: $viewport--md) {
            width: 8rem;
        }
        svg {
            display: block;
            height: 100%;
            width: 100%;
            max-height: 1.2rem;
            max-width: 1.2rem;
            @include mq($from: $viewport--md) {
                max-height: 1.6rem;
                max-width: 1.6rem;
            }
        }
        path {
            stroke: $primary-color;
        }
    }

    &:hover, &:focus,
    &.is-hover, &.is-focus {
        .Bar-image {
            img {
                transform: scale(1.2);
                opacity: .9;
            }
        }
        .Bar-icon {
            transform: translateX(.5rem);
        }
    }
}
