/* Icon List */
.Icon-list {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    @include mq($from: 480) {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-auto-flow: column;
        grid-gap: 1rem;
        max-width: 48rem;
    }
    @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
        grid-template-rows: auto;
        grid-template-columns: 1fr;
        grid-auto-flow: row;
    }

    /*
        Elements
     */
    &-item {
        margin-bottom: 0;
        display: flex;
        align-items: center;

        + .Icon-list-item {
            margin-top: 1rem;
            @include mq($from: 480) {
                margin-top: 0;
            }
        }

        /*
            Inner elements
         */
        span {
            height: 3.2rem;
            width: 3.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            padding: u($spacing-unit/3);
            background-color: $primary-color;
            margin-right: u($spacing-unit/2);
            align-self: center;
            flex-shrink: 0;
            flex-grow: 0;
            @include mq($from: 480) {
                margin-bottom: 0;
            }
        }
        .Icon {
            width: 100%;
            &--telephone {
                fill: $white;
            }
            &--mail,
            &--location {
                path {
                    stroke: $white;
                }
            }
        }

        a {
            color: inherit;
            text-decoration: none;
            margin-top: .25rem;

            &:hover,
            &:focus,
            &:active {
                text-decoration: underline;
                text-decoration-color: rgba($text-color, .6);
            }
        }
    }
}
