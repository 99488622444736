/* Fields: file */
.#{$form-namespace}Form-item--file {

    .Form-label {
        display: none;
    }
}

.#{$form-namespace}Form-field--file {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 5rem;

    .Form-field--label {
        white-space: nowrap;
    }

    .Form-field-input {
        cursor: pointer;
        flex-grow: 1;

        &::file-selector-button {
            @include transition($button-transition, .25s, ease-in-out);
            appearance: none;
            background-color: $secondary-color;
            color: $white;
            border: none;
            font-family: $button-font-stack;
            font-size: 1.2rem;
            font-weight: $button-font-weight;
            line-height: 1;
            padding: em($button-padding--t, $button-padding--r, $button-padding--b, $button-padding--l);
            text-align: $button-text-align;
            text-decoration: none;
            text-transform: uppercase;
            letter-spacing: .1rem;
            margin-right: 1rem;
            cursor: pointer;
            &:hover {
                background-color: $primary-color;
            }
        }
    }

    // Fixed or fluid width
    @if is-false($form-element-fluid) and not is-null($form-element--default) {
        @include mq($from: $viewport--sm) {
            max-width: u($form-element--default);
        }
    }
}
