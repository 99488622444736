.Block--hero {
    padding-top: 0;
}

.Hero {
    position: relative;
    z-index: 1;

    /*
        Elements
     */
    &-body {
        @include mq($from: $viewport--md) {
            padding: u($spacing-unit*2) 0;
            max-width: 80%;
        }
        @include mq($from: $viewport--xl) {
            max-width: 70%;
        }
        &-inner {

        }
    }
    &-image {
        position: relative;
        margin-top: u($spacing-unit);
        padding-bottom: 56.25%;
        width: 100%;
        @include mq($from: $viewport--md) {
            margin-top: u($spacing-unit*2);
            padding-bottom: 50%;
        }
        @include mq($from: $viewport--lg) {
            padding-bottom: 40%;
        }
        &-inner {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            @include mq($from: $viewport--md) {
                width: calc(100vw - 4rem);
            }
        }
        img,
        video {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    &-arrow {
        display: none;
        position: absolute;
        top: 100%;
        right: 100%;
        margin-top: -30rem;
        margin-right: 1.5rem;
        @include mq($from: $viewport--md) {
            display: block;
        }
        @include mq($from: $viewport--xxl) {
            margin-top: -36rem;
            margin-right: 6rem;
        }
        svg {
            height: 8rem;
            width: auto;
            fill: $primary-color;
            @include mq($from: $viewport--xxl) {
                height: 10rem;
            }
        }
    }
    &-slider {
        position: relative;
        overflow: hidden;
        .Hero-image-inner {
            width: 100%;
        }
    }

    /*
        Modifiers
     */
    &-- {

    }
}
