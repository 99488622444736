/* Footer */
.Footer {

    /*
        Elements
     */
    // Top
    &-top {
        padding: u($spacing-unit*2) 0;
        background-color: $footer-bg-color;
        color: rgba(white, 0.75);
        font-size: 1.4rem;
        @include mq($from: $viewport--md) {
            padding: u($spacing-unit*3) 0;
        }

        // Top
        // - inner elements
        h5 {
            color: $white;
            @include mq($from: $viewport--md) {
                margin-bottom: 3rem;
            }
        }
        ul {
            list-style: none;
        }
        a {
            color: inherit;
            text-decoration: none;

            &:focus,
            &:hover,
            &:active {
                text-decoration: underline;
                text-decoration-color: rgba($white, .5);
            }
        }
        .Contact-label {
            margin-bottom: 0
        }
        .O {
            opacity: .05;
        }

        // Top
        // - inner
        &Inner {
            @include mq($from: $viewport--md) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }
        // Top
        // - left
        &Left {
            max-width: 80vw;
            margin-bottom: u($spacing-unit);
            @include mq($from: $viewport--sm) {
                max-width: 60vw;
            }
            @include mq($from: $viewport--md) {
                max-width: 32rem;
                margin-bottom: 0;
            }
        }
        // Top
        // - right
        &Right {
            .Grid-cell + .Grid-cell {
                @include mq($until: $viewport--lg - 1) {
                    margin-top: u($spacing-unit);
                }
            }
        }
    }

    // Bottom
    &-bottom {
        padding: u($spacing-unit) 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.4rem;
        color: $cc-grey-medium;

        // Bottom
        // - inner elements
        a:not(.DigitalPulse) {
            color: inherit;
            text-decoration: none;

            &:focus,
            &:hover,
            &:active {
                text-decoration: underline;
                text-decoration-color: rgba($cc-grey-medium, .5);
            }
        }

        // Bottom
        // - inner
        &Inner {
            @include mq($from: $viewport--md) {
                display: flex;
            }
        }
    }
}
