/* Main */
html {
    background-color: $bg-color;
    color: $text-color;
    font-family: $font-sans;
    height: 100%;

    &.fonts-loaded {
        font-family: $font-sans;
    }

    &.hide-scroll {
        overflow: hidden;
    }
}

body {
    height: 100%;
}

main {
    overflow: hidden;
}
