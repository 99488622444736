/*
    Accordion 🪗
 */

$accordion-image-spacing: u($structure-responsive-gutter--r);
$accordion-image-width: u($structure-responsive-gutter--r*2);
$accordion-grid-area-1: main;
$accordion-grid-area-2: side;
// $accordion-line-clamp: 3;
$accordion-aspect-ratio-4-3: 75%;
$accordion-aspect-ratio-16-10: 62.5%;
$accordion-aspect-ratio-16-9: 56.25%;
$accordion-icon-size: 2.1rem;
$accordion-item-padding: 1.8rem;
$accordion-icon-size: 2.1rem;


.Block--accordion {
    padding: 0;
}

.Accordion {
    position: relative;
    z-index: 2;

    /*
        Elements
     */

    // -- 0
    // Inner [*][*]
    &-inner {
        @include mq($from: $viewport--md) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas:
            "#{$accordion-grid-area-1} #{$accordion-grid-area-2}";
        }
    }

    // -- 1
    // Images [*][ ]
    &-images {
        position: relative;
        left: -$accordion-image-spacing;
        height: 25vh;
        width: calc(100% + #{$accordion-image-width});
        @include mq($from: $viewport--md) {
            left: 0;
            height: 100%;
            width: 100%;
        }
    }
    // Images
    // - wrapper
    &-imageWrapper {
        background-color: $cc-grey-light;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        @include mq($until: $viewport--md - 1) {
            display: none;
        }
        @include mq($from: $viewport--md) {
            width: 50vw;
        }
        &.is-active {
            opacity: 1;
            @include mq($until: $viewport--md - 1) {
                display: block;
            }
            .Accordion-collageItem {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
    // Images
    // - image
    &-image {
        height: 100%;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    // Images
    // - collage
    &-collage {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas:
            "1 2"
            "3 4";
        grid-gap: u($spacing-unit);
        align-content: stretch;
        height: 100%;

        /*
            Modifiers
         */

        &--type-1,
        &--type-4 {
            .Accordion-collageItem {
                &:nth-child(1) {
                    .Accordion-collageImage {
                        padding-bottom: 100%;
                        margin-top: -15%;
                        margin-left: 20%;
                    }
                }
                &:nth-child(2) {
                    .Accordion-collageImage {
                        padding-bottom: $accordion-aspect-ratio-16-9;
                    }
                }
                &:nth-child(3) {
                    .Accordion-collageImage {
                        padding-bottom: $accordion-aspect-ratio-4-3;
                    }
                }
                &:nth-child(4) {
                    .Accordion-collageImage {
                        padding-bottom: 100%;
                        margin-right: 20%;
                    }
                }
            }
        }
        &--type-2,
        &--type-5 {
            .Accordion-collageItem {
                &:nth-child(1) {
                    .Accordion-collageImage {
                        padding-bottom: $accordion-aspect-ratio-4-3;
                    }
                }
                &:nth-child(2) {
                    .Accordion-collageImage {
                        padding-bottom: 100%;
                        margin-top: -15%;
                        margin-right: 20%;
                    }
                }
                &:nth-child(3) {
                    .Accordion-collageImage {
                        padding-bottom: 100%;
                        margin-left: 20%;
                    }
                }
                &:nth-child(4) {
                    .Accordion-collageImage {
                        padding-bottom: $accordion-aspect-ratio-4-3;
                    }
                }
            }
        }
        &--type-3,
        &--type-6 {
            .Accordion-collageItem {
                &:nth-child(1) {
                    .Accordion-collageImage {
                        padding-bottom: 100%;
                        margin-top: -15%;
                        margin-left: 20%;
                    }
                }
                &:nth-child(2) {
                    .Accordion-collageImage {
                        padding-bottom: $accordion-aspect-ratio-4-3;
                    }
                }
                &:nth-child(3) {
                    .Accordion-collageImage {
                        padding-bottom: $accordion-aspect-ratio-4-3;
                    }
                }
                &:nth-child(4) {
                    .Accordion-collageImage {
                        padding-bottom: 100%;
                        margin-right: 20%;
                    }
                }
            }
        }
    }
    // Images
    // - collage item
    &-collageItem {
        opacity: 0;
        transform: scale(.5);
        &:nth-child(1),
        &:nth-child(2) {
            display: flex;
            align-items: flex-end;
        }
        &:nth-child(1) {
            transition:
                opacity .15s $ease-out-quart,
                transform .25s $ease-out-quart;
        }
        &:nth-child(2) {
            transition:
                opacity .15s $ease-out-quart .1s,
                transform .25s $ease-out-quart .15s,;
        }
        &:nth-child(3) {
            transition:
                opacity .15s $ease-out-quart .25s,
                transform .25s $ease-out-quart .3s,;
        }
        &:nth-child(4) {
            transition:
                opacity .15s $ease-out-quart .4s,
                transform .25s $ease-out-quart .45s,;
        }
    }
    // Images
    // - collage item wrapper
    &-collageItemWrapper {
        position: relative;
        width: 100%;
    }
    // Images
    // - collage image
    &-collageImage {
        position: relative;
        img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // -- 2
    // Content [ ][*]
    &-content {
        padding: u($spacing-unit*2) 0;
        @include mq($from: $viewport--md) {
            padding: u($spacing-unit*4) 0 u($spacing-unit*4) u($spacing-unit*2);
        }
        @include mq($from: $viewport--lg) {
            padding-left: u($spacing-unit*4);
        }
        @include mq($from: $viewport--xl) {
            padding-left: u($spacing-unit*5);
        }
    }

    // Items
    &-items {

    }

    // Item
    &-item {
        padding: $accordion-item-padding 0;

        + .Accordion-item {
            border-top: 1px solid rgba($cc-grey-dark, .1);
        }

        &.is-active {
            .Accordion-icons {
                svg {
                    &:first-child {
                        display: block;
                    }
                    &:last-child {
                        display: none;
                    }
                }
            }
            .Accordion-body {
                display: block;
            }

        }
        &:not(.is-active) {
            cursor: pointer;
        }
    }

    // Header
    &-header {
        padding-right: $accordion-icon-size;
    }
    // Header
    // - title
    &-title {
        @include font-size($heading-size-6, $heading-spacing-6);
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1px;
        margin-bottom: 0;
    }
    // Header
    // - icons
    &-icons {
        position: absolute;
        top: $accordion-item-padding;
        right: 0;
        pointer-events: none;
        height: $accordion-icon-size;
        svg {
            display: block;
            height: 100%;
            width: auto;
            &:first-child {
                display: none;
                stroke: $primary-color;
            }
        }
    }

    // Body
    &-body {
        display: none;
        margin-top: 1rem;
        padding-right: $accordion-icon-size;

        // Body
        // - inner elements
        h2 {

        }
        h3 {

        }
        p {
            display: -webkit-box;
            // -webkit-line-clamp: $accordion-line-clamp;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 1.4rem;
            ~ p {
                display: none;
            }
        }
        *:last-child {
            margin-bottom: 0;
        }
        .Button {

        }
    }

    /*
        Modifiers
     */

    &--image-right {
        // Images [ ][*]
        .Accordion-images {
            grid-area: $accordion-grid-area-2;
        }
        // Content [*][ ]
        .Accordion-content {
            grid-column: $accordion-grid-area-1;
            @include mq($from: $viewport--md) {
                padding-left: 0;
                padding-right: u($spacing-unit*2);
            }
            @include mq($from: $viewport--lg) {
                padding-right: u($spacing-unit*4);
            }
            @include mq($from: $viewport--xl) {
                padding-right: u($spacing-unit*5);
            }
        }
        // Image wrapper
        .Accordion-imageWrapper {
            right: auto;
            left: 0;
        }
    }
    &--layout-collage {
        @include mq($from: $viewport--md) {
            padding: u($spacing-unit*2) 0 u($spacing-unit);
        }
        @include mq($from: $viewport--lg) {
            padding: u($spacing-unit*4) 0 u($spacing-unit*3);
        }
        .Accordion-images {
            left: 0;
            height: auto;
            width: 100%;
        }
        .Accordion-imageWrapper {
            width: 100%;
            @include mq($until: $viewport--md - 1) {
                position: static;
            }
        }
        .Accordion-collage {
            @include mq($until: $viewport--md - 1) {
                position: relative;
                top: u($spacing-unit);
                &[data-accordion-images="1"],
                &[data-accordion-images="2"] {
                    grid-template-rows: none;
                    grid-template-areas: "1 2";
                    height: calc(50vw - #{u($structure-responsive-gutter--r*2)});
                }
                &[data-accordion-images="3"],
                &[data-accordion-images="4"] {
                    height: calc(100vw - #{u($structure-responsive-gutter--r*2)});
                }
            }
        }
    }
}
