.Block--numbers {

}

.Numbers {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: u($spacing-unit);
    @include mq($from: $viewport--sm) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: u($spacing-unit*2);
    }
    @include mq($from: $viewport--md) {
        grid-template-columns: repeat(4, 1fr);
    }

    /*
        Elements
     */
    &-item {
        border-left: 2px solid $primary-color;
        padding: u($spacing-unit/8) 0 u($spacing-unit/8) u($spacing-unit/2);
        transition: transform .2s ease-out;
    }
    &-number,
    &-label {
        display: block;
    }
    &-number {
        display: flex;
        color: $primary-color;
        font-size: rem(28px);
        font-weight: 600;
    }
    &-label {
        font-size: rem(16px);
        text-transform: lowercase;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}
