/* Fields: select */
select {
    cursor: pointer;
    width: 100% !important;

    // Fixed or fluid width
    @if is-false($form-element-fluid) and not is-null($form-element--default) {
        @include mq($from: $viewport--sm) {
            max-width: u($form-element--default);
        }
    }
}

.#{$form-namespace}Form-field--select {
    @extend %Form-field--reset;
    @extend %Form-field--shadow;
    @include background-svg('<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="m24 28.1 13.2-14.1 2.8 2.9-16 17.1-16-17.1 2.8-2.9z" fill="white" opacity="0.75"/></svg>');
    background-position: right top 1.35em;
    background-repeat: no-repeat;
    background-size: u($spacing-unit--sm);
    box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow);
    line-height: normal;
    overflow: hidden;
    padding: em($form-element-padding--t, $form-select-element-icon-width, $form-element-padding--b, $form-element-padding--l);

    font-weight: 300;
    border-bottom: 1px solid var(--input-border-color, #{rgba($cc-grey-medium, 0.5)});
    color: var(--select-color, #{rgba($cc-grey-medium, 0.75)});

    option {
        color: var(--select-option-color, #{rgba($cc-grey-medium, 0.75)});
    }

    &:focus {
        border-bottom-color: var(--input-border-color-focus, #{$primary-color});
    }
}

.Hero--light,
.Hero--white,
.Section--light,
.Section--white {

    .#{$form-namespace}Form-field--select {
        @include background-svg('<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="m24 28.1 13.2-14.1 2.8 2.9-16 17.1-16-17.1 2.8-2.9z" fill="black" opacity="0.75"/></svg>');
    }
}

.#{$form-namespace}Form-field--select[multiple] {
    @extend %Form-field--reset;
    height: auto;

    option {
        padding: em(halve($form-element-padding--t), $form-element-padding--r, halve($form-element-padding--b), $form-element-padding--l);

        + option {
            border-top: 1px solid $form-element-border-color;
        }
    }
}
