.Block {

    &--form {

        .Text {
            font-weight: 400;
            font-size: u(25);
            line-height: u(35);

            strong {
                color: $cc-blue;
                font-weight: 400;
            }
        }

        .Form, .Text {
            margin: 0 auto;
            max-width: u(580);
        }

        .Form {
            &-item--file {
                .Form-field--label {
                    background-color: $white;
                }
            } 

            &-field--file {
                .Form-indicator {
                    &::after {
                        background-color: $cc-grey-medium;
                        background-image: none;
                    }
                }
            } 
        }
    }
}