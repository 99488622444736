.Contact-label {
    position: relative;
    display: flex;
    align-content: center;
    .Icon {
        margin-right: u($spacing-unit/2);
        height: 1.6rem;
        width: 1.6rem;
        align-self: center;
        &--telephone {
            fill: currentColor;
        }
        &--mail,
        &--location {
            path {
                stroke: currentColor;
            }
        }
    }
    a {
        color: inherit;
        text-decoration: none;

        &:focus,
        &:hover,
        &:active {
            text-decoration: none;
        }
    }
}
