/* Form */

// Item
.#{$form-namespace}Form-item:not(.#{$form-namespace}Form-item--action) {
    margin-bottom: u($form-item-spacing);
}

// Label
.#{$form-namespace}Form-label {
    display: inline-block;
    margin-bottom: u($form-label-spacing--b);

    // Style label if it's a optional item
    .#{$form-namespace}Form-item:not(.is-required) &[data-optional]::after {
        content: attr(data-optional);
        font-size: 65%;
        font-style: italic;
        margin-left: u($spacing-unit--xs);
        text-transform: lowercase;
    }

    // Error
    .#{$form-namespace}Form-item.has-error & {
        color: $form-error-color;
    }

    // Success
    .#{$form-namespace}Form-item.is-success & {
        color: $form-success-color;
    }
}

// Help & Validation
.#{$form-namespace}Form-help {
    margin-top: u($spacing-unit--xs);
    color: $form-help-color;
    font-size: u($form-help-font-size);

    &:first-child {
        margin-top: 0;
        margin-bottom: u($spacing-unit--sm);
    }

    & :last-child {
        margin-bottom: 0;
    }

    & + & {
        margin-top: u($spacing-unit--sm);
        padding-top: u($spacing-unit--sm);
        border-top: 1px solid $form-divider-color;
    }
}

.#{$form-namespace}Form-help--validation {

    // Error
    .#{$form-namespace}Form-item.has-error & {
        color: $form-error-color;
    }

    // Success
    .#{$form-namespace}Form-item.is-success & {
        color: $form-success-color;
    }
}

// Controls
.#{$form-namespace}Form-controls {
    position: relative;
}

// Horizontal
@include mq($from: $viewport--sm) {
    .#{$form-namespace}Form--horizontal {

        .#{$form-namespace}Form-item {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
        }

        // Label
        .#{$form-namespace}Form-label {
            margin-top: u($form-label-spacing--t);
            margin-bottom: 0;
            padding-right: u($spacing-unit);
            width: percentage($form-label-width);
        }

        // Controls
        .#{$form-namespace}Form-controls {
            width: percentage($form-controls-width);
        }

        // Actions
        .#{$form-namespace}Form-item--action {
            padding-left: percentage($form-label-width);
        }
    }
}

// reCAPTCHA
// .grecaptcha-badge {
//     visibility: hidden;
// }

.Form-item--input {
    position: relative;

    &.is-active {

        .Form-label {
            color: var(--form-label-color-active, #{$primary-color});
            transform: translateY(-0.5rem) scale(0.75);
        }
    }
}

.Form-item--input {

    .Form-label {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(1.4rem) scale(1);
        transform-origin: top left;
        transition: transform 0.2s;
        z-index: 2;
        color: var(--form-label-color, #{rgba($cc-grey-medium, 0.75)});
        pointer-events: none;
        white-space: nowrap;
    }
}

.Form-item--action {
    margin-top: u($spacing-unit);
}

.Form-item--html {

    + .Form-item {
        margin-top: 1.5rem;
    }

    .Form-controls {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        // Only Child
        &:only-child {
            height: 100%;
        }

        // Reset Spacing
        > * {
            margin-top: 0;
            margin-bottom: 0;
        }

        // Default Spacing
        > * + * {
            margin-top: u($spacing-unit);
        }
    }

    h1, h2, h3, h4, h5, h6 {
        -webkit-text-fill-color: transparent;
        -webkit-box-decoration-break: clone;
        font-weight: 500;
        color: $primary-color;
    }
}

.Form-item--action + .Form-item--html {
    margin-top: u($spacing-unit);
    color: var(--form-html-color, #{rgba($cc-grey-medium, 0.5)});
    font-size: 1.3rem;
}

.Form-item--step-0 {

    .FormStep-action--previous {
        display: none;
    }
}

.Form-item--select {
    position: relative;

    .Form-field--label {
        color: rgba($cc-grey-medium, 0.75);
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }

    .Form-label {
        display: none;
    }
}
