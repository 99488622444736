/* Object: Button */
.#{$button-namespace}Button {
    @include transition($button-transition, .25s, ease-in-out);
    -webkit-appearance: none; // Correct the inability to style clickable types in iOS and Safari.
    background-color: transparent;
    border-radius: if($button-border-radius > 0, px($button-border-radius), 0);
    border: px($button-border-width) solid transparent;
    color: inherit;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: $button-font-stack;
    font-size: $button-font-size;
    font-weight: $button-font-weight;
    line-height: $button-line-height;
    padding: em($button-padding--t, $button-padding--r, $button-padding--b, $button-padding--l);
    text-align: $button-text-align;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: .1rem;
    user-select: none;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    transform: translateZ(0); // Small hack for 'Overflow: hidden' with border radius not working on Safari ...

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 120%;
        height: 100%;
        transform: skew(30deg) translate3d(-110%,0,0);
        transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
    }

    span {
        position: relative;
    }

    // Remove excess padding and border in Firefox 4+
    &::-moz-focus-inner {
        border-style: none;
        padding: 0;
    }

    &:hover,
    &.is-hover {
        color: inherit;
        text-decoration: none;
    }

    &:active,
    &:hover,
    &.is-active,
    &.is-hover {
        &:before {
            transform: translate3d(-10%,0,0);
        }
    }

    // States
    &:focus,
    &.is-focus {
        outline: 1px dashed currentColor;
        outline-offset: .5rem;
    }
    &:focus:not(:focus-visible) {
        outline: none;
    }

    &:disabled,
    &.is-disabled {
        box-shadow: none;
        cursor: default;
        opacity: strip-unit($button-disabled-opacity / 100);
        pointer-events: none;
    }
}

.Buttons {
    display: flex;
    flex-wrap: wrap;
    margin: u(-$spacing-unit/4);
    > .Button {
        margin: u($spacing-unit/4);
    }
}
