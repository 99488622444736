/*
    O 🖼
 */

$o-offset: -11rem;
$o-object-height: 22rem;
$o-object-width: 22rem;

.O {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;

    /*
        Elements
     */
    /* div */
    &-inner {
        position: absolute;
        height: $o-object-height;
        width: $o-object-width;
        margin-top: var(--o-object-margin-top);
        margin-left: var(--o-object-margin-bottom);
    }
    /* svg */
    &-object {
        display: block;
        width: 100%;
        fill: var(--o-object-color);
        opacity: var(--o-object-opacity);
    }

    /*
        Modifiers
     */
    &--up,
    &--down {

    }
    &--up {

    }
    &--down {

    }

    /*
        Properties
     */
    // - position
    &:not([data-o-position]) {
        .O-inner {
            top: $o-offset;
            left: $o-offset;
        }
    }

    &[data-o-position="bottom right"] {
        .O-inner {
            bottom: $o-offset;
            right: $o-offset;
        }
    }

    &[data-o-position="offset-right"] {
        .O-inner {
            top: u(280);
            right: u(-130);
        }
    }

    &[data-o-position="mid right"] {
        .O-inner {
            top: 40%;
            right: $o-offset;
            transform: translateY(-50%);
        }
    }

    &[data-o-position="bottom left"] {
        .O-inner {
            bottom: $o-offset;
            left: $o-offset;
        }
    }

    &[data-o-position="top right"] {
        .O-inner {
            top: $o-offset;
            right: $o-offset;
        }
    }

    &[data-o-position="top left"] {
        .O-inner {
            top: $o-offset;
            left: $o-offset;
        }
    }

    // - color
    /*&[data-o-color="white"] {
        .O-object {
            fill: #fdfdfd;
        }
    }*/
    // - size
    &[data-o-size="large"] {
        .O-inner {
            height: $o-object-height*2;
            width: $o-object-width*2;
        }
    }
}
