/* Tile */

.Tiles {
    margin: u(-$spacing-unit/4);
    @include mq($from: $viewport--md) {
        display: flex;
    }
    > .Tile {
        margin: u($spacing-unit/4);
    }
    &.Slider-items {
        /*
            Destroy Slider/Swiper ...
         */
        @include mq($until: $viewport--md - 1) {
            display: block !important;
            transform: none !important;
            margin: 0;
            .Slider-item {
                margin: 0;
                width: calc(100% + 2rem) !important; // container padding (2rem)
            }
            ~ * {
                display: none !important;
            }
            &:before {
                content: "";
                position: absolute;
                top: 10rem;
                left: -2rem; // container padding
                height: calc(100% - 20rem); // 20rem = 10rem (top item) + 10rem (height .Block--overlap)
                width: 100vw;
                background-color: $white;
            }
        }
        + .Slider-buttons {
            position: relative;
            background: red;
            height: 10px;
            margin-top: 100px;
        }
    }
}


.Tile {
    display: block;
    position: relative;
    text-decoration: none;
    &-inner {
        @include mq($from: $viewport--md) {
            padding-bottom: 125%;
        }
    }
    &-logo {
        position: relative;
        height: 5rem;
        width: 14rem;
        display: flex;
        @include mq($until: $viewport--md - 1) {
            position: absolute;
            top: u($spacing-unit);
            left: u($spacing-unit);
        }
    }
    &-image {
        position: relative;
        padding-bottom: 50%;
        width: 100%;
        @include mq($from: $viewport--md) {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            padding-bottom: 0;
        }
        img {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
            @include mq($until: $viewport--md - 1) {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: rgba($primary-color, .8);
            opacity: 0;
            mix-blend-mode: multiply;
            transition: opacity .3s ease-out;
        }
    }
    &-body {
        display: flex;
        flex-direction: column;
        padding: u($spacing-unit);
        color: $text-color;
        @include mq($until: $viewport--md - 1) {
            h4,
            p {
                color: $text-color !important;
            }
        }
        @include mq($from: $viewport--md) {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            padding: u($spacing-unit*1.5) u($spacing-unit*2);
            color: $white;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(180deg, rgba($primary-color, .4), rgba($primary-color, .9) 70%);
            transition: opacity .3s ease-out;
            opacity: 0;
        }
        &-inner {
            position: relative;
            margin-top: auto;
            h4 {
                position: relative;
                margin-bottom: 3rem;
                @include mq($from: $viewport--md) {
                    margin-bottom: 2rem;
                }
                &:after {
                    content: "";
                    width: 2.8rem;
                    height: .2rem;
                    background-color: $primary-color;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    margin-top: 1.2rem;
                    @include mq($from: $viewport--md) {
                        margin-top: .8rem;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                    &:after {
                        content: none;
                    }
                }
            }
            p {
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                overflow: hidden;

                transition: opacity .4s $ease-out-expo .2s, max-height .3s $ease-out-expo .1s;
                @include mq($from: $viewport--md) {
                    opacity: 0;
                    max-height: 0;
                }
                ~ p {
                    display: none !important;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &:hover, &:focus,
    &.is-hover, &.is-focus {
        .Tile {
            &-image {
                &:before {
                    opacity: 1;
                }
            }
            &-body {
                &:before {
                    @include mq($from: $viewport--md) {
                        opacity: 1;
                    }
                }
                &-inner {
                    h4 {

                    }
                    p {
                        @include mq($from: $viewport--md) {
                            opacity: 1;
                            max-height: 14.4rem; // 2.4 (line-height) * 6 (lines);
                        }
                    }
                }
            }
        }
    }
}
