/* Link */
a {
    @include transition(#{background-color, color});
    color: $link-color;
    text-decoration: $link-text-decoration;
    text-underline-offset: 0.2em;

    &:hover,
    &:focus,
    &:active {
        color: $link-color;
        text-decoration-color: $link-color;
    }

    &:focus-visible {
        outline: 1px dashed currentColor;
        outline-offset: .5rem;
    }
}
