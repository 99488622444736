/**
 * Spacing
 */

// Top

/// None

.u-marginTn {
    margin-top: 0 !important;
}

.u-paddingTn {
    padding-top: 0 !important;
}


/// Extra small

.u-marginTxs {
    margin-top: u($spacing-unit--xs) !important;
}

.u-paddingTxs {
    padding-top: u($spacing-unit--xs) !important;
}


/// Small

.u-marginTsm {
    margin-top: u($spacing-unit--sm) !important;
}

.u-paddingTsm {
    padding-top: u($spacing-unit--sm) !important;
}


/// Medium

.u-marginTmd {
    margin-top: u($spacing-unit) !important;
}

.u-paddingTmd {
    padding-top: u($spacing-unit) !important;
}


/// Large

.u-marginTlg {
    margin-top: u($spacing-unit--lg) !important;
}

.u-paddingTlg {
    padding-top: u($spacing-unit--lg) !important;
}


/// Extra large

.u-marginTxl {
    margin-top: u($spacing-unit--xl) !important;
}

.u-paddingTxl {
    padding-top: u($spacing-unit--xl) !important;
}



// Right

/// None

.u-marginRn {
    margin-right: 0 !important;
}

.u-paddingRn {
    padding-right: 0 !important;
}


/// Extra small

.u-marginRxs {
    margin-right: u($spacing-unit--xs) !important;
}

.u-paddingRxs {
    padding-right: u($spacing-unit--xs) !important;
}


/// Small

.u-marginRsm {
    margin-right: u($spacing-unit--sm) !important;
}

.u-paddingRsm {
    padding-right: u($spacing-unit--sm) !important;
}


/// Medium

.u-marginRmd {
    margin-right: u($spacing-unit) !important;
}

.u-paddingRmd {
    padding-right: u($spacing-unit) !important;
}


/// Large

.u-marginRlg {
    margin-right: u($spacing-unit--lg) !important;
}

.u-paddingRlg {
    padding-right: u($spacing-unit--lg) !important;
}


/// Extra large

.u-marginRxl {
    margin-right: u($spacing-unit--xl) !important;
}

.u-paddingnRxl {
    padding-right: u($spacing-unit--xl) !important;
}



// Bottom

/// None

.u-marginBn {
    margin-bottom: 0 !important;
}

.u-paddingBn {
    padding-bottom: 0 !important;
}

/// Expansion
/// ...
.u-marginBn-lastChild {
    > * {
        margin-bottom: 0 !important;
    }
}


/// Extra small

.u-marginBxs {
    margin-bottom: u($spacing-unit--xs) !important;
}

.u-paddingBxs {
    padding-bottom: u($spacing-unit--xs) !important;
}


/// Small

.u-marginBsm {
    margin-bottom: u($spacing-unit--sm) !important;
}

.u-paddingBsm {
    padding-bottom: u($spacing-unit--sm) !important;
}


/// Medium

.u-marginBmd {
    margin-bottom: u($spacing-unit) !important;
}

.u-paddingBmd {
    padding-bottom: u($spacing-unit) !important;
}


/// Large

.u-marginBlg {
    margin-bottom: u($spacing-unit--lg) !important;
}

.u-paddingBlg {
    padding-bottom: u($spacing-unit--lg) !important;
}


/// Extra large

.u-marginBxl {
    margin-bottom: u($spacing-unit--xl) !important;
}

.u-paddingBxl {
    padding-bottom: u($spacing-unit--xl) !important;
}



// Left

/// None

.u-marginLn {
    margin-left: 0 !important;
}

.u-paddingLn {
    padding-left: 0 !important;
}


/// Extra small

.u-marginLxs {
    margin-left: u($spacing-unit--xs) !important;
}

.u-paddingLxs {
    padding-left: u($spacing-unit--xs) !important;
}


/// Small

.u-marginLsm {
    margin-left: u($spacing-unit--sm) !important;
}

.u-paddingLsm {
    padding-left: u($spacing-unit--sm) !important;
}


/// Medium

.u-marginLmd {
    margin-left: u($spacing-unit) !important;
}

.u-paddingLmd {
    padding-left: u($spacing-unit) !important;
}


/// Large

.u-marginLlg {
    margin-left: u($spacing-unit--lg) !important;
}

.u-paddingLlg {
    padding-left: u($spacing-unit--lg) !important;
}


/// Extra large

.u-marginLxl {
    margin-left: u($spacing-unit--xl) !important;
}

.u-paddingLxl {
    padding-left: u($spacing-unit--xl) !important;
}
