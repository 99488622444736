.Block--textMedia {

}

.TextMedia {


    /*
        Elements
     */
    &-body {
        h2 {

        }
        h3 {

        }
        &-inner {

        }
    }
    &-object {

    }

    /*
        Modifiers
     */
    &--left,
    &--right {
        @include mq($from: $viewport--md) {
            display: flex;
            margin: 0 u(-$spacing-unit*2);
        }
        .TextMedia-body,
        .TextMedia-object {
            @include mq($from: $viewport--md) {
                padding: 0 u($spacing-unit*2);
                width: 50%;
            }
        }
        .TextMedia-body {

        }
        .TextMedia-object {

        }
    }
    &--left {
        @include mq($from: $viewport--md) {
            flex-direction: row-reverse;
        }
    }
}
