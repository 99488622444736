/* Main */
.Nav--main {

    @include mq($from: $viewport--md) {
        margin-left: u($spacing-unit);
    }

    // List
    .Nav-list {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include mq($from: $viewport--md) {
            flex-direction: row;
        }
    }

    // Item
    .Nav-item {

        + .Nav-item {
            margin-top: u($spacing-unit--sm);
            @include mq($from: $viewport--md) {
                margin-top: 0;
                margin-left: u($spacing-unit);
            }
        }

        @include mq($until: $viewport--md - 1) {
            + .Nav-item {
                margin-top: u($spacing-unit--sm);
            }
            .show-nav & {
                @include transition(#{opacity, transform}, #{300ms, 1s}, #{linear, cubic-bezier(.19,1,.22,1)});

                @for $i from 1 through 10 {
                    $delay: (0.02s * $i);

                    &:nth-child(#{$i}) {
                        transition-delay: $delay;
                    }
                }
            }
            html:not(.show-nav) & {
                opacity: 0;
                transform: translateY(#{u($spacing-unit)});
            }
        }
    }

    // Link
    .Nav-link {
        color: currentColor;
        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
            text-decoration-color: rgba($primary-color, .75);
        }
    }
}
