/* Settings: Fonts */

// Font imports
@font-face {
    font-family: Konnect;
    src: url('../fonts/konnect/KonnectLight.otf');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: Konnect;
    src: url('../fonts/konnect/KonnectLightItalic.otf');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: Konnect;
    src: url('../fonts/konnect/KonnectRegular.otf');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: Konnect;
    src: url('../fonts/konnect/KonnectItalic.otf');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: Konnect;
    src: url('../fonts/konnect/KonnectSemiBold.otf');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: Konnect;
    src: url('../fonts/konnect/KonnectSemiBoldItalic.otf');
    font-weight: 600;
    font-style: italic;
}

// Custom
$font-custom-font:                      "Konnect", serif !default;

// Default
$font-sans:                             $font-custom-font;
$font-mono:                             SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
