/* Colors: Theme */

// Lay-out
$primary-color:                     $cc-blue !default;
$secondary-color:                   $cc-grey-dark !default;
$tertiary-color:                    $orange !default;

$text-color:                        $cc-grey-medium !default;
$heading-color:                     $cc-grey-dark !default;
$bg-color:                          $white !default;
$border-color:                      $cc-grey-light !default;
$image-color:                       $grey--200 !default;

$header-bg-color:                   $white !default;
$footer-bg-color:                   $cc-grey-dark !default;

// Modifiers
$theme-modifiers: (
    "primary": $primary-color,
    "secondary": $secondary-color,
    "success": $green--400,
    "info": $blue,
    "warning": $orange,
    "danger": $red--400,
    "white": $white,
    "grey-light": $cc-grey-light,
    "grey-medium": $cc-grey-medium,
    "grey-dark": $cc-grey-dark,
    "black": $cc-black
) !default;
