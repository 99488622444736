/* Navigation */
.Navigation {

    &-toggle {
        display: block;
        position: relative;
        z-index: 2;
    }

    &-body {
        display: flex;
        @include mq($until: $viewport--md - 1) {
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100%;
            z-index: 1;
            @include transition(#{opacity, visibility});
            background-color: $header-bg-color;
            flex-direction: column;
            justify-content: center;
            padding: u($spacing-unit);
            html:not(.show-nav) & {
                opacity: 0;
                visibility: hidden;
            }
            > .Nav {
                padding-left: 0;
            }
            .Nav-link {
                font-size: 2.4rem;
            }
        }
    }
}

/* Types */
@import '6-components/navigation/main';
@import '6-components/navigation/lang';
@import '6-components/navigation/footer';
