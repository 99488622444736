/* Objects: Alert */

// Namespace
$container-namespace:                   $namespace !default;

// Width
$container-width:                       1360 !default;

// Sizes
$container-sizes: (
    'xs': 700,
    'sm': 840,
    'md': 1280,
    'lg': $container-width,
    'xl': 1440
);
