.Block {
    &--company-info {
        padding: u(60);
        margin-top: u(100);
        margin-bottom: u(140);

        @include mq($until: $viewport--md - 1) {
            padding: u(40, 40);
            width: calc(100% + 40px);
            margin-left: u(-20);
        }

        @include mq($until: $viewport--sm - 1) {
            padding: u(40, 20);
        }

        .Grid-cell {
            * {
                &:last-child {
                    margin: 0;
                }
            }
        }

        .Lead {

            .Media {
                @include dimensions(90px);
                margin: u(0, 35, 0, 0);
                display: inline-block;
                float: left;

                &-object {
                    width: u(90px);
                }
            }
        }

    }
}