/* Header */
.Header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $header-bg-color;
    padding-top: u($spacing-unit*2);
    padding-bottom: u($spacing-unit*2);
    transition: transform .3s ease;

    > .Container {
        display: flex;
        align-items: center;
    }

    &--sticky {
        will-change: transform;

        &.is-pinned {
            transform: translateY(0%);
        }
        &.is-unpinned {
            transform: translateY(-100%);
        }
        &.is-notTop {
            box-shadow: 0 0 10px 0 rgba($cc-grey-dark, 0.25);
        }
        &.is-notTop {
            &.is-pinned {
                transform: translateY(0%);
                box-shadow: 0 0 10px 0 rgba($cc-grey-dark, 0.25);
            }
            &:not(.is-pinned):not(.is-unpinned) {
                transform: translateY(-100%);
                box-shadow: 0 0 10px 0 rgba($cc-grey-dark, 0.25);
            }
        }
    }
}
