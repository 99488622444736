/* Components: Button */

// Namespace
$button-namespace:                    $namespace !default;

// Padding
$button-padding--t:                   1rem  !default;
$button-padding--r:                   1.8rem !default;
$button-padding--b:                   1rem !default;
$button-padding--l:                   1.8rem !default;

// Typography
$button-font-stack:                   null !default;
$button-font-weight:                  600;
$button-line-height:                  1 !default;
$button-font-size:                    1.4rem;
$button-text-align:                   center !default;

// Borders
$button-border-width:                 1 !default;
$button-border-radius:                50% !default;

// States
$button-focus-width:                  0.2rem !default;
$button-focus-color:                  rgba($blue, 0.25) !default;
$button-focus-shadow:                 0 0 0 $button-focus-width $button-focus-color !default;

// Transition
$button-transition:                   (background-color, border-color, box-shadow, color) !default;

// Disabled
$button-disabled-opacity:             60% !default;
