/* Logo */
.Logo {
    flex-shrink: 0;
    margin-right: auto;
    max-width: 18rem;
    position: relative;
    z-index: 2;

    @include mq($from: $viewport--md) {
        max-width: 20rem;
    }

    svg {
        display: block;
        width: 100%;
    }

    &--white {
        svg > * {
            fill: $white;
        }
    }
}
