/* Link */

.Link {
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: .1rem;
    text-underline-offset: .2rem;
}

.Link--primary {
    color: $secondary-color;
    &:hover, &:focus {
        color: $primary-color;
    }
}
