/* Blocks */
.Block {
    position: relative;
    padding: 5rem 0;

    @include mq($from: $viewport--md) {
        padding: 10rem 0;
    }

    > .Container {
        position: relative;
        z-index: 2;
    }

    /*
        ...
     */
    &:not([class*="Block--bg-"]) {
        + .Block:not([class*="Block--bg-"]) {
            padding-top: 0;
        }
    }

    /*
        Modifiers
     */
    &.Block--accordion {
        padding-top: 0;
    }

    &--overlap {
        position: relative;
        margin-top: -5rem;
        @include mq($from: $viewport--md) {
            margin-top: -10rem;
        }
        &:before {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 0;
            height: 10rem;
            width: 100%;
            @include mq($from: $viewport--md) {
                height: 20rem;
            }
        }
    }

    @each $modifier, $color in $theme-modifiers {
        &--bg-#{$modifier} {
            background-color: $color;
            --backgroundColor: $color;
            @if ($modifier == "grey-dark" or $modifier == "black" or $modifier == "primary" or $modifier == "secondary") {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                .Tagline,
                a:not([class]),
                p:not([class]) {
                    color: $white;
                }
                .Button--primary {
                    &.Button--outline {
                        background-color: transparent;
                        border-color: $white;
                        color: $white;
                        &:before {
                            background-color: $white;
                        }
                        &:active,
                        &:hover,
                        &.is-active,
                        &.is-hover {
                            color: $text-color;
                        }
                    }
                }
            }
            &.Block--overlap {
                &:before {
                    background-color: $color;
                }
            }
        }
    }
}
