/* Icon */
.Icon {
    //fill: currentColor;
    display: block;
    overflow: visible;

    .Button &,
    .Link & {
        flex-shrink: 0;
        height: 1.4rem;
        width: 1.4rem;
        position: relative;
        top: -.1rem;
        margin-right: .6rem;
        path {
            stroke: currentColor;
        }
    }
}
